import React from 'react';
import {Helmet} from "react-helmet";

import { useStaticQuery, graphql } from "gatsby";

import { dateString, EventTile } from '../components/event';

import { PageContainer } from '../components/container';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));


const DateDivider = (props) => {
  return <Grid item xs={12} textalign="center">
    <Typography variant="h2">{dateString(props.start)}</Typography>
  </Grid>
};

const IndexPage = ({page, data}) => {
  const todaysDate = new Date();
  const todaysDateStr = todaysDate.getDate();
  // const data = useStaticQuery(query);
  // console.log(page);
  // console.log(data);

  const classes = useStyles();

  let currentDate = null;

  const events = data.allStrapiEvents.edges;

  return (<PageContainer>
    <Container className={classes.cardGrid} maxWidth="md">
      <Helmet>
          <title>Rave Texas - Upcoming Texas EDM Events and Festivals</title>
      </Helmet>
          
      <Grid container spacing={4}>
        {events.map(({"node": event}) => {
          return (
          <>
            {(currentDate !== dateString(event.start)) ? <DateDivider start={event.start} currentDate={currentDate = dateString(event.start)}></DateDivider> : <></>}
            <EventTile key={event.id} event={event} />
          </>
        )})}
      </Grid>
    </Container>
  </PageContainer>);

  // return (<div><ul>{data.allStrapiEvents.edges.map((item) => <li>{item.node.title}</li>)}</ul></div>);
};

// const query2 = graphql`
// query {
//   events (sort:"start:ASC", where: {
//   	start_gte:"2021-12-26T18:00:00.000Z" 
//   }) {
//     id
//     title
//     url
//     cover {
//       url
//       width
//       height
//     }
//     organizer {
//       id
//       name
//       url
//     }
//     venue {
//       id
//       name
//       address
//       latitude
//       longitude
//     }
//   }
// }
// `;

export const query = graphql`
query FutureEvents ($today: Date!) {
  allStrapiEvents (filter: {
    end: { gte: $today },
    slug: { ne: null }
  }, sort: {fields: [start], order: ASC}) {
    edges {
      node {
        id
        title
        url
        start
        end
        slug

        cover {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 640)
            }
          }
        }
        organizer {
          id
          name
          url
        }
        venue {
          id
          name
          address
          latitude
          longitude
        }
      }
    }
  }
}
`

export default IndexPage
